import { reactLocalStorage } from "reactjs-localstorage";
import validator from "validator";
import { POST } from "./API";

export default _this => {
  global.phone = p => {
    let ph = `${p}`;
    ph = ph.replace("+", "");
    ph = ph.replace("(", "");
    ph = ph.replace(")", "");
    ph = ph.replace("-", "");
    ph = ph.replace(" ", "");
    return ph;
  };

  global.user = reactLocalStorage.getObject("user");

  global.signIn = (
    params_ = {},
    before_ = () => {},
    success_ = () => {},
    error_ = () => {}
  ) => {
    let { username, password } = params_;
    before_();
    POST("login", {
      [validator.isEmail(username) ? "emailaddress" : "mobilenumber"]: username,
      password
    })
      .then(({ data }) => {
        success_(data);
      })
      .catch(error => {
        error_(error);
      });
  };

  global.loading = loading => _this.setState({ loading });

  global.reloadApp = () => {
    global.user = reactLocalStorage.getObject("user");

    _this.setState({
      reloadAppIndex: _this.state.reloadAppIndex + 1,
      user: global.user,
      loading: false
    });
  };

  global.genQr = id => {
    let qr = `${id}`;
    let qr_ = new Array(8 - qr.length).fill("0");
    qr_ = [...qr_, ...qr.split("")];
    qr_.reverse();
    qr = qr_.join("");
    return qr;
  };

  return {
    genQr: global.genQr,
    getUser: () => {
      return reactLocalStorage.getObject("user");
    },
    setUser: (user, extraState = {}) => {
      reactLocalStorage.setObject("user", user);
      global.user = user;
      _this.setState({ user, ...extraState });
    },
    appLoading: loading => {
      _this.setState({ loading });
    },
    isNotFirst: () => {
      let is = reactLocalStorage.getObject("isNotFirst");
      return Object.keys(is).length > 0;
    },
    setNotFirst: () => {
      reactLocalStorage.setObject("isNotFirst", { isNotFirst: true });
    },
    signedIn: () => {
      return Object.keys(_this.state.user).length > 0;
    }
  };
};

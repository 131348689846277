export const typeRoles = {
  quitus: 'Quitus',
  mission: 'TradeMission',
  training: 'Training',
  contribution: 'AdHocContribution',
  insurance: 'MedicalInsurance',
};

export default {
  quitus: 'Quitus',
  mission: 'Trade Mission',
  training: 'Training',
  contribution: 'Ad-Hoc Contribution',
  insurance: 'Medical Insurance',
};

import React, { lazy } from "react";
import { isMobile } from "react-device-detect";

import { Anchor, Typography } from "antd";

import { Redirect, Switch, Route, Link, matchPath } from "react-router-dom";

import Menu from "components/Menu";
import publicMenu from "menus/publicMenu";

import { withData } from "utils/DataContext";
import Logo from "components/Logo";
import WaitingComponent from "components/WaitingComponent";

const Invoice = lazy(() => import("pages/public/Invoice"));
const Home = lazy(() => import("pages/public/Home"));
const Payment = lazy(() => import("pages/public/Payment"));
const Activity = lazy(() => import("pages/public/Activity"));
const Activate = lazy(() => import("pages/public/Activate"));
const Reactivate = lazy(() => import("pages/public/Reactivate"));
const SignUp = lazy(() => import("pages/public/SignUp"));
const Token = lazy(() => import("pages/public/Token"));
const ResetPassword = lazy(() => import("pages/public/ResetPassword"));
const Exhibit = lazy(() => import("pages/public/Exhibit"));
const Events = lazy(() => import("pages/public/Events"));
const Event = lazy(() => import("pages/public/Events/Event"));
const Tenders = WaitingComponent(lazy(() => import("pages/public/Tenders")));
const Venues = WaitingComponent(lazy(() => import("pages/public/Venues")));
const Payables = WaitingComponent(lazy(() => import("pages/public/Payables")));
const Membership = lazy(() => import("pages/public/Membership"));
const SignIn = WaitingComponent(lazy(() => import("pages/public/SignIn")));
const Survey = WaitingComponent(lazy(() => import("pages/public/Survey")));
const NoMatch = lazy(() => import("pages/NoMatch"));

var { Title } = Typography;

const verify = (signedIn, Comp, props) => {
  if (signedIn()) {
    return <Redirect to="/" />;
  } else {
    return <Comp {...props} />;
  }
};

const makeClass = (path) => ["__public public", ...path.split("/")].join("_");

class Public extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signingOut: false,
      _class: makeClass(props.history.location.pathname),
    };
  }

  componentDidMount = () => {
    this.props.history.listen((location, action) => {
      this.setState({ _class: makeClass(location.pathname) });

    });
  };

  signOut = () => {
    if (!this.state.signingOut) {
      this.setState({ signingOut: true });
      this.props.appLoading(true);
      setTimeout(() => {
        this.props.history.push("/sign-in");
        setTimeout(() => {
          this.props.setUser({}, { loading: false });
        }, 300);
      }, 2000);
    }
  };
  render = () => {
    let { _class } = this.state;
    if(matchPath(this.props.history.pathname,{path:"/events/{eventid}",exact:true}) && isMobile){
     return <Redirect path={`#${this.props.history.pathname}`}/>
    }
    return (
      <Switch>
        <Route exact path="/invoice" component={WaitingComponent(Invoice)} />
        <Route
          render={() => (
            <div id="public" className={_class}>
              <div className="the-public-bg" />
              <Anchor className="custom-anchor">
                <div className="logo">
                  <Link to="/">
                    <Logo all scale={50} />
                  </Link>
                </div>
                <Menu
                  props={{
                    mode: "horizontal",
                    style: {
                      lineHeight: "56px",
                    },
                  }}
                  menu={publicMenu(this.props.user)}
                />
              </Anchor>
              <div className="public-inner">
                <div>
                  <Switch>
                    <Route exact path="/" component={WaitingComponent(Home)} />

                    {!isMobile && (
                      <Route
                        exact
                        path={["/sign-in", "/sign-in/:email"]}
                        render={(props) =>
                          !this.props.signedIn() ? (
                            <SignIn {...props} />
                          ) : (
                            <Redirect to="/dashboard" />
                          )
                        }
                      />
                    )}
                    <Route
                      exact
                      path="/sign-out"
                      render={(props) => {
                        if (!this.props.signedIn()) {
                          return <Redirect to="/sign-in" />;
                        } else {
                          this.signOut();
                          return <Title>Signing out...</Title>;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/reset-password"
                      render={(props) =>
                        verify(
                          this.props.signedIn,
                          WaitingComponent(ResetPassword),
                          props
                        )
                      }
                    />
                    <Route
                      exact
                      path={["/token", "/token/:email"]}
                      render={(props) =>
                        verify(
                          this.props.signedIn,
                          WaitingComponent(Token),
                          props
                        )
                      }
                    />
                    <Route
                      exact
                      path="/s/:id"
                      component={WaitingComponent(Survey)}
                    />
                    <Route
                      exact
                      path="/events"
                      component={WaitingComponent(Events)}
                    />
                    <Route
                      exact
                      path="/events/:eventid"
                      component={WaitingComponent(Event)}
                    />
                    <Route
                      exact
                      path="/exhibit"
                      component={WaitingComponent(Exhibit)}
                    />
                    <Route
                      exact
                      path="/sign-up"
                      render={(props) =>
                        verify(
                          this.props.signedIn,
                          WaitingComponent(SignUp),
                          props
                        )
                      }
                    />
                    <Route
                      exact
                      path={["/activate", "/activate/:email"]}
                      render={(props) =>
                        verify(
                          this.props.signedIn,
                          WaitingComponent(Activate),
                          props
                        )
                      }
                    />
                    <Route
                      exact
                      path="/reactivate"
                      render={(props) =>
                        verify(
                          this.props.signedIn,
                          WaitingComponent(Reactivate),
                          props
                        )
                      }
                    />

                    <Route
                      exact
                      path="/directory"
                      component={WaitingComponent(Activity)}
                    />
                    <Route
                      exact
                      path="/payment"
                      component={WaitingComponent(Payment)}
                    />
                    <Route
                      exact
                      path="/tenders"
                      component={WaitingComponent(Tenders)}
                    />
                    <Route
                      exact
                      path="/venues"
                      component={WaitingComponent(Venues)}
                    />
                    <Route
                      exact
                      path="/:type(quitus|mission|training|contribution|insurance)"
                      component={WaitingComponent(Payables)}
                    />
                    <Route
                      exact
                      path="/membership"
                      component={WaitingComponent(Membership)}
                    />
                    <Route component={WaitingComponent(NoMatch)} />
                  </Switch>
                </div>
              </div>
            </div>
          )}
        />
      </Switch>
    );
  };
}

export default withData(Public);

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

import 'antd/dist/antd.css';

Sentry.init({
  dsn: 'https://babb07754e4742979367094a25ae4170@sentry.io/1826386',
});

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

// ReactDOM.render(<App />, document.getElementById("root"));
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

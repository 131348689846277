import servicesMenu from "menus/servicesMenu";
import topMenu from "menus/topMenu";
export default (user) => [
  {
    to: "/",
    title: "Home",
    icon: "home",
  },
  {
    to: "/dashboard",
    title: "Statistics",
    icon: "project",
  },
  ...[
    user.type === "ADMIN"
      ? {
          to: "/dashboard/messaging",
          title: "Messaging",
          icon: "message",
          roles: ["GetMessages"],
          menu: [
            {
              to: "/dashboard/messaging/sent",
              title: "Sent Messages",
              roles: ["GetMessages"],
            },
            {
              to: "/dashboard/messaging",
              title: "New Message",
              roles: ["CreateMessages"],
            },
          ],
        }
      : {},
  ],
  ...(user.type !== "ADMIN" ? [servicesMenu(user)] : []),  

...topMenu(user.username),
];

import payableTypes from "pages/private/Payables/types";

export default (user) => ({
  to: "/tenders",
  title: "Services",
  icon: "check-circle",
  menu: [
    { to: "/directory", title: "Business Directory" },
    { to: "/tenders", title: "Tenders" },
    { to: "/venues", title: "Venues To Hire" },
    ...(user.type === "MEMBER"
      ? ["quitus", "mission", "training", "contribution", "insurance"].map(
          (tp) => ({
            to: `/${tp}`,
            title: payableTypes[tp],
          }),
        )
      : []),
  ],
});

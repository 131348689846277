import React, { lazy } from 'react';

import { Anchor,Layout, Input } from 'antd';

import { Switch } from 'react-router';
import WaitingComponent from 'components/WaitingComponent';
import { Route } from 'utils/hasRoles';

import Menu from 'components/Menu';

import mainMenu from 'menus/mainMenu';
import topLeftMenu from 'menus/topLeftMenu';
import topMenu from 'menus/topMenu';

import { withData } from 'utils/DataContext';
import Logo from 'components/Logo';
import { isMobile } from "react-device-detect";
const NoMatch = lazy(() => import('pages/NoMatch'));
const Events = lazy(() => import('pages/private/Events'));
const Users = lazy(() => import('pages/private/Users'));
const Members = lazy(() => import('pages/private/Members'));
const Directory = lazy(() => import('pages/private/Directory'));
const Surveys = lazy(() => import('pages/private/Surveys'));
const Payments = lazy(() => import('pages/private/Payments'));
const Dashboard = lazy(() => import('pages/private/Dashboard'));
const Audit = lazy(() => import('pages/private/Audit'));
const Tenders = lazy(() => import('pages/private/Tenders'));
const Venues = lazy(() => import('pages/private/Venues'));
const Issues = lazy(() => import('pages/private/Issues'));
const Payables = lazy(() => import('pages/private/Payables'));
const Contacts = lazy(() => import('pages/private/Contacts'));
const Settings = lazy(() => import('pages/private/Settings'));
const Messages = lazy(() => import('pages/private/Messages'));
const Reports = lazy(() => import('pages/private/Reports'));
const BulkMessage = lazy(() => import('components/BulkMessage'));
const SMS = lazy(() => import('pages/private/Messages/sms'));
const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;

var moment = require('moment');

class Private extends React.Component {
  // state = {
  //   collapsed: false,
  // };

   state = isMobile ? {
    collapsed: true,
  }
   : {
    collapsed: false,
  };
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render = () => {
    let { user } = this.props;
    return (
      <Layout id='private' style={{ minHeight: '100vh' }}>
        <Sider
          className='left-nav'
          collapsible
          collapsed={this.state.collapsed}
         onCollapse={this.onCollapse}
      //  collapsedWidth={0}
         // trigger={0}
        >
          <div className='logo'>
            <Logo all={!this.state.collapsed} scale={50} />
          </div>
          <Menu
            props={{ theme: 'dark', mode: 'inline' }}
            menu={mainMenu(user)}
          />
        </Sider>
        <Layout>
          {/* <Header
            className='top-nav'
            style={{
             padding: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          > */}

         
        
          <Content style={{ margin: '0 16px' }}>
            <div
              style={{
                margin: '16px 0',
              }}
            >

            <Anchor className="custom-anchor">

<Menu
  props={{ mode: 'horizontal', style: { lineHeight: '64px',textAlign: 'right' } }}
  menu={topLeftMenu(user)}
/>
{/* <Search
  placeholder='input search text'
  disabled={true}
  onSearch={(value) => console.log(value)}
  style={{
    margin: '16px',
    flex: '1',
  }}
/> */}
{/* <Menu
  props={{ mode: 'horizontal', style: { lineHeight: '64px' } }}
  menu={topMenu(user.username)}
/> */}
</Anchor>
              <Switch>
                <Route
                  exact
                  path='/dashboard'
                  component={WaitingComponent(Dashboard)}
                />
                <Route
                  roles={['GetEvents']}
                  path='/dashboard/events'
                  component={WaitingComponent(Events)}
                />
                <Route
                  // roles={['GetUsers']}
                  path='/dashboard/users'
                  component={WaitingComponent(Users)}
                />
                <Route
                  roles={['GetMembers']}
                  or={['member']}
                  path='/dashboard/members'
                  component={WaitingComponent(Members)}
                />
                <Route
                  roles={['GetQueries']}
                  or={['member']}
                  path='/dashboard/:type(queries)'
                  component={WaitingComponent(Issues)}
                />
                <Route
                  roles={['GetAdvocacy']}
                  or={['member']}
                  path='/dashboard/:type(advocacy)'
                  component={WaitingComponent(Issues)}
                />
                <Route
                  roles={['GetQuitus']}
                  path='/dashboard/:type(quitus)'
                  component={WaitingComponent(Payables)}
                />
                <Route
                  roles={['GetTradeMission']}
                  path='/dashboard/:type(mission)'
                  component={WaitingComponent(Payables)}
                />
                <Route
                  roles={['GetTraining']}
                  path='/dashboard/:type(training)'
                  component={WaitingComponent(Payables)}
                />
                <Route
                  roles={['GetAdHocContribution']}
                  path='/dashboard/:type(contribution)'
                  component={WaitingComponent(Payables)}
                />
                <Route
                  roles={['GetMedicalInsurance']}
                  path='/dashboard/:type(insurance)'
                  component={WaitingComponent(Payables)}
                />
                <Route
                  roles={['GetDirectory']}
                  path='/dashboard/directory'
                  component={WaitingComponent(Directory)}
                />
                <Route
                  roles={['GetSurveys']}
                  path='/dashboard/surveys'
                  component={WaitingComponent(Surveys)}
                />
                <Route
                  roles={['GetAudit']}
                  exact
                  path='/dashboard/audit'
                  component={WaitingComponent(Audit)}
                />
                <Route
                  roles={['GetReports']}
                  path='/dashboard/reports'
                  component={WaitingComponent(Reports)}
                />
                <Route
                  roles={['GetTenders']}
                  path='/dashboard/tenders'
                  component={WaitingComponent(Tenders)}
                />
                <Route
                  roles={['GetPayments']}
                  or={['member']}
                  path='/dashboard/payments'
                  component={WaitingComponent(Payments)}
                />
                <Route
                  roles={['GetVenues']}
                  path='/dashboard/venues'
                  component={WaitingComponent(Venues)}
                />
                <Route
                  roles={['GetContacts']}
                  path='/dashboard/contacts'
                  component={WaitingComponent(Contacts)}
                />
                <Route
                  roles={['GetSettings']}
                  exact
                  path='/dashboard/settings'
                  component={WaitingComponent(Settings)}
                />
                <Route
                  roles={['GetMessages', 'CreateMessages']}
                  exact
                  path='/dashboard/messaging'
                  component={WaitingComponent(BulkMessage)}
                />
                <Route
                  roles={['GetMessages']}
                  exact
                  path='/dashboard/messaging/sent'
                  component={WaitingComponent(Messages)}
                />
                <Route
                  roles={['GetMessages', 'CreateMessages']}
                  exact
                  path='/dashboard/messaging/sms'
                  component={WaitingComponent(SMS)}
                />
                <Route component={WaitingComponent(NoMatch)} />
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            PSF ©{moment().format('YYYY')} Created by{' '}
            <a target='blank_' href='https://www.fdibiz.com/'>
              FDI
            </a>
          </Footer>
        </Layout>
      </Layout>
    );
  };
}

export default withData(Private);

import React from "react";

import MenuComp from "antd/lib/menu";
import Icon from "antd/lib/icon";

import { withRouter } from "react-router-dom";

import { withRoles } from "utils/hasRoles";

import { withData } from "utils/DataContext";

const SubMenuItem = (props) => {
  let prps = { ...props };
  let menu = { ...prps.menu };
  delete prps.menu;
  return (
    <MenuComp.SubMenu
      {...prps}
      key={`sub-${menu.to}`}
      title={
        <span>
          {menu.icon && <Icon type={menu.icon} />}
          <span>{menu.title}</span>
        </span>
      }
    >
      {menu.menu.map((submenu) =>
        submenu.menu && submenu.menu.length > 1 ? (
          withRoles(
            submenu.roles,
            submenu.or,
            props.user,
            <SubMenu key={submenu.to} menu={submenu} />,
          )
        ) : submenu.menu && submenu.menu.length === 1 ? (
          withRoles(
            submenu.menu[0].roles,
            submenu.menu[0].or,
            props.user,
            <MenuComp.Item key={submenu.menu[0].to}>
              {submenu.icon && <Icon type={submenu.icon} />}
              <span>{submenu.menu[0].title}</span>
            </MenuComp.Item>,
          )
        ) : submenu === "divider" ? (
          <MenuComp.Divider />
        ) : (
          withRoles(
            submenu.roles,
            submenu.or,
            props.user,
            <MenuComp.Item key={submenu.to}>
              {submenu.icon && <Icon type={submenu.icon} />}
              <span>{submenu.title}</span>
            </MenuComp.Item>,
          )
        ),
      )}
    </MenuComp.SubMenu>
  );
};

const SubMenu = withData(SubMenuItem);

class Menu extends React.Component {
  render = () => {
    let { location, history, props = {}, menu = [], user } = this.props;
    let { pathname } = location;

    return (
      <MenuComp
        {...props}
        overflowedIndicator={<Icon type="menu" />}
        selectedKeys={[pathname]}
        onClick={({ key }) => {
          history.push(key);
        }}
      >
        {menu.map((menuItem) =>
          menuItem.menu && menuItem.menu.length > 1 ? (
            withRoles(
              menuItem.roles,
              menuItem.or,
              user,
              <SubMenu key={menuItem.to} menu={menuItem} />,
            )
          ) : menuItem.menu && menuItem.menu.length === 1 ? (
            withRoles(
              menuItem.menu[0].roles,
              menuItem.menu[0].or,
              user,
              <MenuComp.Item key={menuItem.menu[0].to}>
                {menuItem.icon && <Icon type={menuItem.icon} />}
                <span>{menuItem.menu[0].title}</span>
              </MenuComp.Item>,
            )
          ) : menuItem === "divider" ? (
            <MenuComp.Divider />
          ) : (
            withRoles(
              menuItem.roles,
              menuItem.or,
              user,
              <MenuComp.Item key={menuItem.to}>
                {menuItem.icon && <Icon type={menuItem.icon} />}
                <span>{menuItem.title}</span>
              </MenuComp.Item>,
            )
          ),
        )}
      </MenuComp>
    );
  };
}

export default withData(withRouter(Menu));

export default [
  {
    to: `/dashboard/directory`,
    title: 'Companies',
  },
  {
    to: `/dashboard/directory/fees`,
    title: 'Directory Fees',
    roles: ['GetDirectoryFees'],
  },
];

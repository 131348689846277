import usersMenu from 'menus/usersMenu';
import membersMenu from 'menus/membersMenu';
import surveysMenu from 'menus/surveysMenu';
import issuesMenu from 'menus/issuesMenu';
import directoryMenu from 'menus/directoryMenu';
import reportsMenu from 'menus/reportsMenu';

import payableTypes from 'pages/private/Payables/types';

export default (user, noSubs = false) => [
  ...(user.type === 'ADMIN'
    ? [
        {
          to: '/dashboard/events',
          title: 'Events',
          icon: 'calendar',
          roles: ['GetEvents'],
        },
      ]
    : []),
  {
    to: '/dashboard/members',
    title: 'Membership',
    icon: 'team',
    roles: ['GetMembers'],
    menu: noSubs ? [] : membersMenu(user),
  },
  {
    to: '/dashboard/payments',
    title: `${user.type === 'ADMIN' ? '' : 'My '}Payments History`,
    icon: 'history',
    roles: ['GetPayments'],
    or: ['member'],
  },
  {
    to: '/dashboard/queries',
    title: 'Queries',
    icon: 'question-circle',
    roles: ['GetQueries'],
    menu: issuesMenu(user, 'issues'),
  },
  {
    to: '/dashboard/advocacy',
    title: 'Advocacy',
    icon: 'warning',
    roles: ['GetAdvocacy'],
    menu: issuesMenu(user, 'advocacy'),
  },
  {
    to: '/dashboard/surveys',
    title: 'Surveys',
    icon: 'copy',
    roles: ['GetSurveys'],
    ...(user.type === 'ADMIN' ? { menu: noSubs ? [] : surveysMenu(user) } : {}),
  },
  ...(user.type === 'ADMIN'
    ? [
        {
          to: '/dashboard/directory',
          title: 'Directory',
          icon: 'database',
          roles: ['GetDirectory'],
          menu: directoryMenu,
        },
        {
          to: '/dashboard/tenders',
          title: 'Tenders',
          roles: ['GetTenders'],
          icon: 'ordered-list',
        },
        {
          to: '/dashboard/venues',
          title: 'Venues',
          icon: 'environment',
          roles: ['GetVenues'],
        },
        {
          to: '/dashboard/quitus',
          title: 'Services',
          icon: 'unordered-list',
          roles: ['GetServices'],
          menu: [
            ...[
              'quitus',
              'mission',
              'training',
              'contribution',
              'insurance',
            ].map((tp) => ({
              to: `/dashboard/${tp}`,
              title: payableTypes[tp],
              roles: [
                `Get${
                  {
                    quitus: 'Quitus',
                    mission: 'TradeMission',
                    training: 'Training',
                    contribution: 'AdHocContribution',
                    insurance: 'MedicalInsurance',
                  }[tp]
                }`,
              ],
            })),
          ],
        },
        // { to: '/dashboard/contacts', title: 'Contacts', icon: 'team' },
        {
          to: '/dashboard/users',
          title: 'Users',
          icon: 'user',
          roles: ['GetUsers'],
          menu: noSubs ? [] : usersMenu,
        },
        {
          to: '/dashboard/reports',
          title: 'Reports',
          icon: 'file',
          roles: ['GetReports'],
          menu: reportsMenu,
        },
        {
          to: '/dashboard/audit',
          title: 'Audit',
          icon: 'clock-circle',
          roles: ['GetAudit'],
        },
      ]
    : []),
];

import React, { Suspense } from "react";
import Logo from "components/Logo";
import Spin from "antd/lib/spin";
const WaitingComponent = Component => {
  return props => (
    <Suspense
      fallback={
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 999,
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.3)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Logo scale={140} all />
          <Spin spinning size="large" />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default WaitingComponent;

export default [
  {
    to: `/dashboard/reports/members`,
    title: 'Members',
    roles: ['GetMembers'],
  },
  {
    to: `/dashboard/reports/members-cat`,
    title: 'Category-Members',
    roles: ['GetMembers'],
  },
  {
    to: `/dashboard/reports/directory`,
    title: 'Directory',
    roles: ['GetDirectory'],
  },
  {
    to: `/dashboard/reports/events`,
    title: 'Events',
    roles: ['GetEvents'],
  },
  // {
  //   to: `/dashboard/reports/queries`,
  //   title: 'Queries',
  //   roles: ['GetQueries'],
  // },
  // {
  //   to: `/dashboard/reports/advocacy`,
  //   title: 'Advocacy',
  //   roles: ['GetAdvocacy'],
  // },
];

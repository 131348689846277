import React from 'react';

export default class Logo extends React.Component {
  render = () => {
    let { scale = 200 } = this.props;

    return (
      <div
        style={{
          height: scale,
          width: scale * 0.678,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div style={{ transform: `scale(${scale / 690})` }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                height: 690,
                width: 471,
                padding: 20,
                backgroundColor: '#fff',
              }}
            >
              <img
                style={{
                  height: 650,
                  width: 431,
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
                alt=''
                src={require('./../assets/images/PSF_LOGO.jpg')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

import React from "react";
import { isMobile } from "react-device-detect";
import {
  HashRouter,
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import * as Sentry from "@sentry/browser";
// import * as firebase from "firebase/app";
import Spin from "antd/lib/spin";

import { Context } from "utils/DataContext";

import globals from "./utils/globals";
import hasRoles from "./utils/hasRoles";

import "./App.scss";

import Public from "./pages/public";
import Private from "./pages/private";

// const Router = isMobile ? HashRouter : BrowserRouter;
const Router = isMobile ? BrowserRouter : BrowserRouter;


export default class App extends React.Component {
  constructor(props) {
    super(props);

    let {
      getUser,
      setUser,
      appLoading,
      signedIn,
      isNotFirst,
      setNotFirst,
    } = globals(this);

    this.state = {
      user: getUser(),
      getUser,
      setUser,
      appLoading,
      signedIn,
      isNotFirst,
      setNotFirst,
      loading: false,
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render = () => {
    let {
      user,
      getUser,
      setUser,
      appLoading,
      signedIn,
      isNotFirst,
      setNotFirst,
      loading,
    } = this.state;

    return (
      <div className="App">
        <Context.Provider
          value={{
            user,
            getUser,
            setUser,
            appLoading,
            signedIn,
            isNotFirst,
            setNotFirst,
            hasRoles: (roles, or) => hasRoles(roles, or, user),
          }}
        >
          <Spin spinning={loading} size="large">
            <Router>
              <Switch>
               
                  <Route
                    path="/dashboard"
                    render={(props) =>
                      signedIn() ? <Private {...props} /> : <Redirect to="/" />
                    }
                  />
                
                <Route path="/" render={(props) => <Public {...props} />} />
              </Switch>
            </Router>
          </Spin>
        </Context.Provider>
        {process.env.REACT_APP_ENV === "development" && (
          <div id="test-env-badge">Test Environment</div>
        )}
      </div>
    );
  };
}

import React, { lazy } from 'react';
import { Route as Rt } from 'react-router';
import { withData } from './DataContext';

import WaitingComponent from 'components/WaitingComponent';
const NoMatch = lazy(() => import('pages/NoMatch'));

const hasRolesFn = (roles = [], or = [], user) => {
  if (user.profile && user.profile.roles) {
    const { roles: userRoles, name } = user.profile;

    const compare = (v) => userRoles.includes(v);

    return (
      roles.every(compare) ||
      (or.length > 0 && or.every(compare)) ||
      roles.includes(name) ||
      or.includes(name)
    );
  }
  return false;
};

const TheRoute = ({ hasRoles, roles = null, or = [], ...props }) =>
  roles ? (
    hasRoles(roles, or) ? (
      <Rt {...props} />
    ) : (
      <Rt {...props} component={WaitingComponent(NoMatch)} />
    )
  ) : (
    <Rt {...props} />
  );

export const Route = withData(TheRoute);

const TheWithRoles = ({
  hasRoles,
  component = () => null,
  roles = [],
  or = [],
}) => (!roles || roles.length === 0 || hasRoles(roles, or)) && component;

export const WithRoles = withData(TheWithRoles);

export const menuRoles = (menu, roles = [], user) => {
  return hasRolesFn(roles, [], user) ? menu : {};
};

export const withRoles = (roles, or, user, ret) =>
  (!roles || roles.length === 0 || hasRolesFn(roles, or, user)) && ret;

export default hasRolesFn;

export default (user) => [
  { to: '/dashboard/surveys', title: 'List' },
  ...(user.type !== 'ADMIN'
    ? []
    : [
        {
          to: '/dashboard/surveys/new',
          title: 'Add New',
          roles: ['CreateSurveys'],
        },
      ]),
];

import axios from 'axios';
export const prod = process.env.REACT_APP_ENV === 'production';

export const logs = process.env.REACT_APP_LOGS === '1';

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const handlerUrl = process.env.REACT_APP_HANDLER_URL;

const apiCall = async (url, method, body, headers, errorMessage) => {
  let token = null;
  let loggedIn = false;

  if (global.user && global.user.token) {
    token = `JWT ${global.user.token}`;
    loggedIn = true;
  }

  return new Promise((resolve, reject) => {
    let theUrl = `${baseUrl}${url}`;

    if (url.startsWith('http://') || url.startsWith('https://')) {
      theUrl = url;
    }

    let props = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
    };

    if (token) {
      props.headers['Authorization'] = token;
    }

    if (method !== 'GET' && method !== 'DELETE') {
      if (props.headers['Content-Type'] === 'application/json')
        props.data = JSON.stringify(body);
      else props.data = body;
    }

    axios(theUrl, props)
      // .then(response => {
      //   // if (response.status === 401 && loggedIn) {
      //   //   window.location.href = '/sign-out';
      //   // }
      //   return response.json();
      // })
      .then(responseJson => {
        if (logs) {
          console.log('\n\n\n\n\n\n\n\n----- Success -----');
          console.log(theUrl);
          console.log(props.headers);
          console.log(props.body);
          console.log(responseJson);
          console.log(JSON.stringify({ theUrl, props, responseJson }));
          console.log('----- /Success -----\n\n');
        }
        if (responseJson.status && !`${responseJson.status}`.startsWith('20')) {
          const obj = {
            message: responseJson.message
              ? responseJson.message
              : 'An error occurred!',
          };
          throw obj;
        } else {
          resolve(responseJson.data);
        }
      })
      .catch(error => {
        if (logs) {
          console.log('\n\n\n\n\n\n\n\n----- Error -----');
          console.log(theUrl);
          console.log(props.headers);
          console.log(props.body);
          console.log(JSON.stringify(error.response, true, 4));
          console.log('----- /Error -----\n\n');
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          reject(
            error.response.data.errors.map(({ message }) => message).join('\n'),
          );
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          reject(error.response.data.message);
        } else {
          reject(error.message || 'An error occurred!');
        }
      });
  });
};

export const GET = (url, body = {}, headers = {}, errorMessage = '') => {
  return apiCall(url, 'GET', body, headers, errorMessage);
};

export const POST = (url, body = {}, headers = {}, errorMessage = '') => {
  return apiCall(url, 'POST', body, headers, errorMessage);
};

export const DELETE = (url, body = {}, headers = {}, errorMessage = '') => {
  return apiCall(url, 'DELETE', body, headers, errorMessage);
};

export const PUT = (url, body = {}, headers = {}, errorMessage = '') => {
  return apiCall(url, 'PUT', body, headers, errorMessage);
};

export const FILE = (name, base64) => {
  return new Promise((resolve, reject) => {
    let props = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, base64 }),
    };

    let theUrl = 'https://files.thepass.app/upload';

    fetch(theUrl, props)
      .then(response => {
        return response.json();
      })
      .then(responseJson => {
        if (!prod) {
          console.log('\n\n\n\n\n\n\n\n----- Success -----');
          console.log(theUrl);
          console.log(props.headers);
          console.log(JSON.stringify(props.body));
          console.log(responseJson);
          console.log('----- /Success -----\n\n');
        }
        if (responseJson.hasOwnProperty('error')) {
          reject(responseJson.error.message);
        } else {
          resolve(responseJson);
        }
      })
      .catch(error => {
        if (!prod) {
          console.log('\n\n\n\n\n\n\n\n----- Error -----');
          console.log(theUrl);
          console.log(props.headers);
          console.log(JSON.stringify(props.body));
          console.log(error);
          console.log('----- /Error -----\n\n');
        }
        reject(error.message || 'An error occurred!');
      });
  });
};

export default apiCall;

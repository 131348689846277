import { isMobile } from "react-device-detect";

import topMenu from "menus/topMenu";
import mainMenu from "menus/mainMenu";
import servicesMenu from "menus/servicesMenu";

export default (user) => [
  { to: "/", title: "Home", icon: "home" },
  {
    to: "/events",
    title: "Events",
    icon: "calendar",
    menu: [
      { to: "/events", title: "All" },
      "divider",
      { to: "/events/?filter=upcoming", title: "Upcoming" },
      { to: "/events/?filter=ongoing", title: "Ongoing" },
      "divider",
      { to: "/events/?filter=past", title: "Past" },
    ],
  },
  {
    to: "/membership",
    title: "Membership",
    icon: "user",
  },
  servicesMenu(user),
  { to: "/payment", title: "Payment", icon: "credit-card" },
  ...(Object.keys(user).length === 0 && !isMobile
    ? [
        { to: "/sign-in", title: "Sign In", icon: "unlock" },
        /*{ to: "/sign-up", title: "Register", icon: "user-add" }*/
      ]
    : isMobile
    ? [ { to: "/membership", title: "Sign In", icon: "unlock" },]
    : [
        {
          to: "/dashboard",
          title: "Dashboard",
          icon: "dashboard",
          menu: [
            {
              to: "/dashboard",
              title: "Statistics",
              icon: "project",
            },
            ...mainMenu(user, false),
          ],
        },
        ...topMenu(user.username),
      ]),
];

export default (username) => [
  {
    to: '/dashboard/users/me',
    title: username,
    icon: 'user',
    menu: [
      { to: '/dashboard/users/me', title: 'My Profile', icon: 'user' },
      'divider',
      { to: '/sign-out', title: 'Sign Out', icon: 'lock' },
    ],
  },
];

export default (user, type = 'issues') => [
  ...(user.type === 'ADMIN'
    ? [
        {
          to: `/dashboard/${type === 'issues' ? 'queries' : 'advocacy'}`,
          title: 'List',
        },
        {
          to: `/dashboard/${
            type === 'issues' ? 'queries' : 'advocacy'
          }/categories`,
          title: 'Categories',
          roles:
            type === 'issues'
              ? ['GetQueriesCategories']
              : ['GetAdvocacyCategories'],
        },
      ]
    : [
        {
          to: `/dashboard/${type === 'issues' ? 'queries' : 'advocacy'}/me`,
          title: `My ${type === 'issues' ? 'Queries' : 'Advocacy Issues'}`,
        },
      ]),
];

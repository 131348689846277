import React from "react";
const Context = React.createContext();

const withData = Component => {
  class DataComponent extends React.Component {
    render() {
      return (
        <Context.Consumer>
          {data => <Component {...data} {...this.props} />}
        </Context.Consumer>
      );
    }
  }

  return DataComponent;
};

export { Context, withData };

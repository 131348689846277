export default (user) => [
  ...(user.type === 'ADMIN'
    ? [
        {
          to: '/dashboard/members',
          title: 'Members',
        },
        {
          to: '/dashboard/members/chambers',
          title: 'Chambers',
          roles: ['GetMembersChambers'],
          menu: [
            {
              to: '/dashboard/members/chambers',
              title: 'Chambers',
              roles: ['GetMembersChambers'],
            },
            {
              to: '/dashboard/members/associations',
              title: 'Associations',
              roles: ['GetMembersAssociations'],
            },
            {
              to: '/dashboard/members/categories',
              title: 'Categories',
              roles: ['GetMembersCategories'],
            },
          ],
        },
        {
          to: '/dashboard/members/provinces',
          title: 'Provinces',
          roles: ['GetMembersProvinces'],
          menu: [
            {
              to: '/dashboard/members/provinces',
              title: 'Provinces',
              roles: ['GetMembersProvinces'],
            },
            {
              to: '/dashboard/members/districts',
              title: 'Districts',
              roles: ['GetMembersDistricts'],
            },
            {
              to: '/dashboard/members/ranges',
              title: 'Ranges',
              roles: ['GetMembersRanges'],
            },
          ],
        },
        {
          to: '/dashboard/members/activities',
          title: 'Activities',
          roles: ['GetMembersActivities'],
        },
      ]
    : [
        { to: '/dashboard/members/me', title: 'My Membership' },
        // { to: '/dashboard/members/me/invoices', title: 'My Invoices' },
      ]),
];
